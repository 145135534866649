<template>
  <div class="list-two">

  <el-header>
    <el-upload
    class="upload-demo"
    accept=".xls,.xlsx"
    action=""
    :show-file-list="true"
    :headers="{ 'Content-Type': 'multipart/form-data' }"
    :http-request="uploadFile"
    :before-upload="beforeUpload"
  >
    <el-button size="small" type="primary" :loading="uploadLoading">导入下单任务</el-button>
  </el-upload>

  </el-header>
    <el-table :data="tableData" border style="width: 100%;" max-height="600" size="small" stripe tooltip-effect="dark" class="center-table">

      <!-- <el-table-column fixed prop="id" label="id" width="60" class-name="custom-header">
      </el-table-column> -->
      <el-table-column prop="no" label="服务单号" width="180">
      </el-table-column>
      <el-table-column prop="contractDeliveryTime" label="合同交期" width="160">
      </el-table-column>
      <el-table-column prop="nodeName" label="节点名称" width="80">
      </el-table-column>
      <el-table-column prop="taskName" label="任务名称" width="80" algin="center">
      </el-table-column>
      <el-table-column prop="status" label="审核状态" width="120" algin="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="danger" size="small">未审核</el-tag>
          <el-tag v-if="scope.row.status === 1" type="success" size="small">已审核</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="urgencyLevel" label="紧急程度" width="80">
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" width="80">
      </el-table-column>
      <el-table-column prop="customerPhone" label="客户电话" width="100">
      </el-table-column>
      <el-table-column prop="administrativeDivision" label="行政区划" width="220">
      </el-table-column>
      <el-table-column prop="customerAddress" label="客户地址" width="180">
      </el-table-column>
      <!-- <el-table-column prop="no" label="服务单号" width="120">
      </el-table-column> -->
      <el-table-column prop="serviceNumber" label="服务号" width="120">
      </el-table-column>
      <el-table-column prop="brandName" label="品牌" width="120">
      </el-table-column>
      <el-table-column prop="categoryName" label="品类" width="120">
      </el-table-column>
      <el-table-column prop="taskStatus" label="任务状态" width="120">
      </el-table-column>
      <el-table-column prop="isComplete" label="是否齐套" width="120">
      </el-table-column>
      <!-- <el-table-column prop="contractDeliveryTime" label="合同交期" width="120">
      </el-table-column> -->
      <el-table-column prop="contractAmount" label="合同款" width="120">
      </el-table-column>
      <el-table-column prop="amountReceived" label="已收" width="120">
      </el-table-column>
      <el-table-column prop="amountDue" label="欠款" width="120">
      </el-table-column>
      <el-table-column prop="contractConfirmationTime" label="合同确认时间" width="120">
      </el-table-column>
      <el-table-column prop="plannedStartTime" label="计划开始时间" width="120">
      </el-table-column>
      <el-table-column prop="plannedEndTime" label="计划完成时间" width="120">
      </el-table-column>
      <el-table-column prop="assigneeName" label="责任人" width="120">
      </el-table-column>
      <el-table-column prop="debtRatio" label="欠款比例" width="120">
      </el-table-column>
      <el-table-column prop="store" label="门店" width="120">
      </el-table-column>
      <el-table-column prop="salesperson" label="导购" width="120">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <!-- <el-button @click="handleDelete(scope.row)" size="mini" type="danger">删除订单</el-button> -->
          <el-button v-if="scope.row.status === 0" @click="handleAudit(scope.row)" size="mini" type="danger">审核</el-button>
          <el-button v-if="scope.row.status === 1" size="mini" type="primary" disabled>已审核</el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>
  
  
<script>
// 可以忽略eslint检查未使用变量错误
// eslint-disable-next-line no-unused-vars
// ... 这里是你的代码 ...
</script>
  
  
  <script>
import {
  uploadAndSortExcel,
  getSortExcel,
  deleteTask,
  auditOrder
} from "../../api/user";

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      row: {}, // 传进来的行数据
      editedData: {}, // 此处为示例，根据实际情况定义需要编辑的数据对象
      uploadLoading: false,
    };
  },
  methods: {
    inputFile(res) {
      console.log(res, "res");
    },

    // 上传前  文件判断
    beforeUpload(file) {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        this.$message.error("请上传xlsx格式文件");
      }
      return isXlsx;
    },

    // 在这里编写文件上传的逻辑
    uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      this.$confirm("确认上传文件吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.uploadLoading = true;

        uploadAndSortExcel(formData)
          .then((res) => {
            console.log(res, 999888);
            this.uploadLoading = false;
            this.$message({
              type: "success",
              message: "上传成功!",
            });
            this.getList();
            console.log("上传成功", res.data);
          })
          .catch((err) => {
            console.log("上传失败", err);
            this.uploadLoading = false;
            this.$message.error("上传失败!");
          });
      });
    },

    // 获取后台排序后任务列表
    getList() {
      getSortExcel()
        .then((res) => {
          this.loading = true;
          // this.$message.success("获取数据成功");
          this.tableData = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          // eslint-disable-line no-unused-vars
          console.log("error submit!! 报错");
          this.loading = false;
        });
    },
    // 审核按钮逻辑
    handleAudit(row){

      // console.log(row);
      this.$confirm('确定开始审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = {
        id:row.id,
        status:"1",

      };
      console.log(arr,"arr222")
        auditOrder(arr).then((res) => { // eslint-disable-line no-unused-vars
          this.loading = true;
          this.$message.success("审核成功");
          this.getList();
          this.loading = false
        }).catch((err) => { // eslint-disable-line no-unused-vars

          console.log('error submit!! 报错');
          this.loading = false
          return false

        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消审核'
        });
      });

    },

    //删除任务
    handleDelete(row) {
      // console.log(row);
      this.$confirm('确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteTask(row.id).then((res) => { // eslint-disable-line no-unused-vars
          this.loading = true;
          this.$message.success("删除成功");
          this.getList();
          this.loading = false
        }).catch((err) => { // eslint-disable-line no-unused-vars

          console.log('error submit!! 报错');
          this.loading = false
          return false

        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },

  },
  created() {
    // 获取列表数据
    this.getList();
  },
};
</script>
  
  
<style lang="scss" scoped>

// 表格内容居中
:deep(.center-table td), 
:deep(.center-table th) {
  text-align: center !important;
}
.el-main {
  padding: 0 !important;
}
</style>